import React from "react";
import shampooEquilibrado from "../../src/components/Imagenes/sh equilibrado cabello normal mixto.jpeg";
import shampooFortCabelloGraso from "../../src/components/Imagenes/shampooFortalecedorCabelloGraso.jpg";
import shampooRestauradorDeCacao from "../../src/components/Imagenes/Shampoorestaurador cabello seco.jpeg";
import balsamoHidratante from "../../src/components/Imagenes/Balsamo-hidratante.jpeg";
import balsamoHumectante from "../../src/components/Imagenes/Balsamo-humectante.jpeg";
import acondicionadorA from "../../src/components/Imagenes/acondicionadoresWeb.jpeg";
import shampooPielSensible from "../../src/components/Imagenes/ShampooPielSensibleDeAlmendrasyNaranjaParaCabelloSano.jpeg";
import cremaContornoDeOjos from "../../src/components/Imagenes/cremaContornoDeOjos.jpeg";
import cremigelFacial from "../../src/components/Imagenes/cremigel-facial.jpeg";
import CremadeLimpiezaFacial from "../../src/components/Imagenes/crema-limpieza-facial.jpeg";
import CremaNutritiva from "../../src/components/Imagenes/crema-nutritiva.jpeg";
import cremaDeManos from "../../src/components/Imagenes/crema-de-manos.jpg";
import PrecioCremaLipiezaFacial from "../firebase/$limpiezaFacial";
import PrecioCremigel from "../firebase/$Cremigel";
import PrecioNutriX3 from "../firebase/$CremaNutriX3";
import PrecioContorno from "../firebase/$CremaContorno";
import PrecioBalsamoHidra from "../firebase/$BalsamoHidra";
import PrecioBalsamoHumec from "../firebase/$BalsamoHumec";
import PrecioShampooEquilibrado from "../firebase/$ShampooEquilib";
import PrecioShampooFortalecedor from "../firebase/$ShampooFortalec";
import PrecioShampooRestaurador from "../firebase/$ShampooRestaurador";
import PrecioShampooPielSensible from "../firebase/$ShampooPielSens";
import PrecioAcondNormalSeco from "../firebase/$AcondNormalSeco";
import PrecioCremaDeManos from "../firebase/$CremaDeManos";

export const Shampoos = [
  {
    id: 1,
    imagen: (
      <img
        title="Shampoo equilibrado para cabello normal a mixto"
        className="imgProduct"
        src={shampooEquilibrado}
        alt="shampoo equilibrado"
      ></img>
    ),
    titulo: "Shampoo equilibrado",
    descripcion: (
      <p className="descriptionText">
        {" "}
        <p className="productTitle">
          Shampoo equilibrado para cabello normal a mixto
        </p>
        <br />
        Esta pastilla de shampoo combina el efecto purificante y fortalecedor de
        la arcilla blanca que ayuda a equilibrar y revitalizar el pelo viéndose
        este más suelto y suave, con el aceite de oliva virgen que contiene
        ácidos grasos omega 9, polifenoles y vitamina E que actúan como
        hidratantes y antioxidantes del cabello dándole brillo y haciéndolo
        menos quebradizo. La manteca de karité, acondiciona el cabello
        proporcionando flexibilidad al mismo. Lo hidrata, renueva su brillo y
        favorece su crecimiento. El aceite esencial de lavanda favorece el
        crecimiento del cabello y aumenta el número de folículos pilosos dando
        lugar a un pelo más abundante y grueso. En aromaterapia, su efecto es
        relajante del sistema nervioso. Por lo que el uso del shampoo será una
        experiencia refrescante y relajante. <br />
        <br />
        <p className="productIngredients">
          {" "}
          Sus ingredientes son: arcilla blanca, aceite de oliva, manteca de
          karité, sci, betaína de coco, glucósido de coco, agua, glicerina,
          fitoqueratina, aceite esencia de lavanda.
        </p>
        <br /> <span>Contenido 80 g. </span> -
        <span>Durabilidad: Lavados 70 aprox.</span> -{" "}
        <span>
          Precio:{" "}
          <b>
            {" "}
            <PrecioShampooEquilibrado></PrecioShampooEquilibrado>
          </b>
        </span>
      </p>
    ),
    dato: "cabello normal a mixto",
  },
  {
    id: 2,
    imagen: (
      <img
        title="Shampoo Fortalecedor de Almendras, Árbol de te y Romero para cabello
      graso"
        className="imgProduct"
        src={shampooFortCabelloGraso}
        alt="shampoo fortalecedor"
      ></img>
    ),
    titulo: "shampoo fortalecedor",

    descripcion: (
      <p className="descriptionText">
        <p className="productTitle">
          Shampoo Fortalecedor de Almendras, Árbol de te y Romero para cabello
          graso
        </p>
        <br />
        Este shampoo está formulado con arcilla verde. La misma tiene
        propiedades absorbentes y funciona como detoxificante, tonificante y
        reafirmante del cuero cabelludo, especialmente en pieles grasas. Es
        antiséptica y seboreguladora. A su vez, el aceite de almendras de la
        fórmula hidrata la piel del cuero cabelludo aportando omega 6 al cabello
        previniendo su caída y aumentando su densidad. Contiene biotina que
        favorece la elasticidad y evita que el cabello se quiebre. <br />
        Los ingredientes estrella del shampoo son: el aceite esencial de Arbol
        de Te, que es un poderoso agente antimicótico y antihistamínico que
        ayuda a controla el sebo del cuero cabelludo y el aceite esencial de
        Romero, que otorga un efecto astringente, bactericida y estimulante del
        sistema circulatorio. Son muy efectivos en el tratamiento del cuero
        cabelludo graso.
        <br />
        <br />
        <p className="productIngredients">
          Ingredientes: Arcilla verde, Aceite de Almendras Dulces, Almidón de
          maíz, Pantenol, Aceite Esencial de Árbol de Te, Aceite Esencial de
          Romero, Glicerina, Sci, Betaína de coco, Glucósido de coco, Agua.
        </p>
        <br /> <span>Contenido 80 g. </span> -
        <span>Durabilidad: Lavados 70 aprox.</span> -{" "}
        <span>
          Precio:
          <b>
            <PrecioShampooFortalecedor></PrecioShampooFortalecedor>
          </b>
        </span>
      </p>
    ),
  },

  {
    id: 3,
    imagen: (
      <img
        title="Shampoo Restaurador de Cacao, Naranja y Pantenol para cabello
      seco/dañado"
        className="imgProduct"
        src={shampooRestauradorDeCacao}
        alt="shampoo restaurador"
      ></img>
    ),
    titulo: "shampoo restaurador",
    descripcion: (
      <p className="descriptionText">
        <p className="productTitle">
          Shampoo Restaurador de Cacao, Naranja y Pantenol para cabello
          seco/dañado
        </p>
        <br />
        Nuestro shampoo restaurador está formulado con avena, un hidratante del
        cabello que lo suaviza y le da brillo. <br />
        Por otro lado, la manteca de cacao, hidratante y humectante emoliente
        con alto contenido de vitamina E, estimula el crecimiento y lo protege
        del daño solar. <br />
        Otros aceites nutritivos de este producto son el de coco que contiene
        una gran proporción de ácido láurico, protector de agresiones externas y
        controlador del encrespamiento y el oliva, gran aliado del cabello
        reseco por su alto contenido de omega 9 que protege la fibra capilar, la
        fortalece y previene la caída. <br />
        Los ingredientes estrella de este shampoo son la fitoqueratina y el
        pantenol. La fitoqueratina es un acondicionador natural compuesto por
        proteínas y amino ácidos que otorgan al cabello capacidad de
        hidratación, disminuyen el frizz y le dan forma. Así mismo, el pantenol
        de la fórmula aumenta el volumen del mismo, lo mantiene humectado y lo
        fortalece desde el cuero cabelludo. <br />
        El aceite esencial de naranjas dulces de es un poderoso estimulante
        antibacteriano de uso tópico, tonifica la piel y por su agradable aroma
        ofrece un efecto calmante, alegre y estimulante. <br />
        <br />{" "}
        <p className="productIngredients">
          Ingredientes: Avena, Manteca de Cacao, Aceite de Coco, Oliva,
          Fitoqueratina, Almidón de Maíz, Pantenol, Aceite Esencial de Naranjas
          Dulces, Glicerina, Sci, Betaína de coco, Glucósido de coco, Agua.
        </p>
        <br /> <span>Contenido 80 g. </span> -
        <span>Durabilidad: Lavados 70 aprox.</span> -{" "}
        <span>
          Precio:
          <b>
            <PrecioShampooRestaurador></PrecioShampooRestaurador>
          </b>
        </span>
      </p>
    ),
  },
  {
    id: 4,
    imagen: (
      <img
        title="Shampoo Piel Sensible de Almendras y Naranja para cabello sano"
        className="imgProduct"
        src={shampooPielSensible}
        alt="shampoo piel sensible"
      ></img>
    ),
    titulo: "shampoo piel sensible",
    descripcion: (
      <p className="descriptionText">
        {" "}
        <p className="productTitle">
          Shampoo Piel Sensible de Almendras y Naranja para cabello sano
        </p>
        <br />
        Shampoo ideal para cabello sano y juvenil. Apto para niños.
        <br />
        <p className="productIngredients">
          Ingredientes: Avena, Almidón de maíz, Manteca de Karité, Aceite de
          Coco, Aceite de Almendras Dulces, Fitoqueratina, Cúrcuma, Aceite
          Esencial de Naranjas Dulces, Glicerina, Sci, Betaína de coco,
          Glucósido de coco, Agua.
        </p>
        <br /> <span>Contenido 80 g. </span> -
        <span>Durabilidad: Lavados 70 aprox.</span> -{" "}
        <span>
          Precio:
          <b>
            <PrecioShampooPielSensible></PrecioShampooPielSensible>
          </b>
        </span>
      </p>
    ),
  },
  {
    id: 5,
    imagen: (
      <img
        title="Acondicionador para cabello seco o normal."
        className="imgProduct"
        src={acondicionadorA}
        alt="Acondicionador para cabello seco o normal."
      ></img>
    ),
    titulo: "Acondicionador para cabello seco o normal.",
    descripcion: (
      <p className="descriptionText">
        {" "}
        <p className="productTitle">
          Acondicionador para cabello normal a seco.
        </p>
        <br />
        Estos acondicionadores sólidos formulados con mantecas y aceites
        naturales son aliados que combaten el cabello seco y las puntas
        abiertas. Disminuyen el frizz, dan brillo y suavidad al cabello.
        Dejándolo fácil de peinar hasta con las manos. Formulados con
        fitoqueratina🌾. La proteína de trigo hidrolizada que no solo es apta
        para el cabello liso, sino que también devuelve la suavidad y definición
        a los rizos.
        <br />
        <p className="productIngredients">
          Ingredientes: Cacao, Coco, Alcohol Cetílico, Tensioactivo catiónico
          btms, Vitamina E, Fitoqueratina.
        </p>
        <br /> <span>Contenido 50 g. </span> -
        <span>Durabilidad: Lavados 45 aprox.</span> -{" "}
        <span>
          Precio:
          <b>
            <PrecioAcondNormalSeco></PrecioAcondNormalSeco>
          </b>
        </span>
      </p>
    ),
  },
];

export const Balsamos = [
  {
    id: 6,
    imagen: (
      <img
        title="balsamo-hidratante"
        className="imgProduct"
        src={balsamoHidratante}
        alt="balsamo hidratante"
      ></img>
    ),
    titulo: "balsamo hidratante",
    descripcion: (
      <p className="descriptionText">
        <p className="productTitle">
          Bálsamo labial hidratante de Karité, Almendras y aceite esencial de
          Naranja.
        </p>
        <br />
        La manteca de Karité nutre y deja la piel de los labios tersa e
        hidratada. Estas propiedades provienen de los ácidos grasos que la
        componen como el ácido esteárico, el oléico y pequeñas cantidades de
        linoléico y palmítico. Es un regenerador celular que protege de
        agresiones externas. Contiene ácido cinámico, que es un compuesto que
        actúa como antiinflamatorio y protector de los rayos UV ya que
        interviene en el metabolismo de enzimas relacionadas con el
        fotoenvejecimiento.
        <br />
        <br />
        <p className="productIngredients">
          Ingredientes: Manteca de Karité, Aceite de Almendras, Cera de Abejas,
          Vitamina E y Ac Esencial de Naranjas.
        </p>
        <br />
        <span>Contenido: 15 g.</span>{" "}
        <span>
          {" "}
          Precio:
          <b>
            {" "}
            <PrecioBalsamoHidra></PrecioBalsamoHidra>{" "}
          </b>{" "}
        </span>
      </p>
    ),
  },
  {
    id: 7,
    imagen: (
      <img
        title="balsamoHumectante"
        className="imgProduct"
        src={balsamoHumectante}
        alt="balsamoHumectante"
      ></img>
    ),
    titulo: "balsamoHumectante",
    descripcion: (
      <p className="descriptionText">
        {" "}
        <p className="productTitle">
          Bálsamo labial humectante de Rosa Mosqueta, Cacao y aceite esencial de
          Naranja.
        </p>
        <br />
        La manteca de cacao es emoliente y suavizante. Forma una película que
        evita la deshidratación. El aceite de Rosa Mosqueta contiene gran
        cantidad de vitamina A y E. Regenera y repara los labios agrietados. La
        vitamina E es un antioxidante natural que protege la piel de los
        radicales libres previniendo el envejecimiento prematuro. La cera de
        abejas es emoliente, antibacteriana y antiinflamatoria. Forma una capa
        de protección transpirable frente a factores externos como el frío y la
        exposición al sol manteniendo la hidratación y mejorando la elasticidad
        de la piel. <br />
        <br />
        <p className="productIngredients">
          Ingredientes: Manteca de Cacao, Aceite de rosa Mosqueta, Aceite de
          Almendras, Cera de Abejas, Vitamina E y Ac Esencial de Naranjas.
        </p>
        <br />
        <span>Contenido: 15 g.</span>{" "}
        <span>
          {" "}
          Precio:
          <b>
            {" "}
            <PrecioBalsamoHumec></PrecioBalsamoHumec>{" "}
          </b>{" "}
        </span>
      </p>
    ),
  },
];
export const Cremas = [
  {
    id: 8,
    imagen: (
      <img
        title="Crema de Limpieza Facial"
        className="imgProduct"
        src={CremadeLimpiezaFacial}
        alt="Crema de Limpieza Facial"
      ></img>
    ),
    titulo: "Crema de Limpieza Facial",
    descripcion: (
      <p className="descriptionText">
        <p className="productTitle">Crema de Limpieza Facial.</p>
        <br />
        Emulsión de limpieza fresca y suave, apta para todo tipo de pieles.
        Formulada a ph 5,5. Esto significa que no se necesita el uso de un
        tónico luego de la crema de limpieza para equilibrar el ph de la piel.
        De esta manera, protege el manto ácido de la misma. Mantiene su humedad
        y la integridad de la flora microbiana natural (bacterias saludables de
        la piel) que impide que bacterias patógenas se desarrollen. <br />
        <em>
          <i>Ricino:</i>
        </em>
        disuelve fácilmente los pigmentos otorgando una función desmaquillante y
        limpiante. Es un acondicionador que le da suavidad a la piel. <br />
        <em>
          <i>Jojoba:</i>
        </em>
        esta cera líquida es un gran hidratante de pieles secas y actúa como
        antioxidante debido a su alto contenido de vitamina E. <br />
        <em>
          <i>Aceite Esencial de Cedro:</i>
        </em>
        tonifica y reafirma la piel. Favorece la circulación y la eliminación de
        toxinas dándole a la piel un aspecto liso y uniforme. <br />
        <em>
          <i>Aceite Esencial de Eucaliptus:</i>
        </em>{" "}
        estimula la producción de ceramidas que retienen la hidratación y
        mejoran la protección de la barrera cutánea de los factores externos.
        Posee propiedades antibacterianas y antisépticas. <br />
        <em>
          <i> Aceite Esencial de Lemongrass:</i>
        </em>{" "}
        estimula y revitaliza cuerpo y mente a través de su aroma alimonado,
        herbáceo y con notas térreas.
        <br />
        <br />
        <p className="productIngredients">
          Ingredientes: agua, aceite de jojoba, aceite de ricino, cera lanette
          N, vitamina E, glicerina, goma xántica, betaína de coco, glucósido de
          coco (emulsionantes naturales derivados del coco), aceites esenciales
          de cedro, eucalipto y lemongrass.
        </p>
        <br />
        <br />
        <span>Contenido: 100 g.</span>{" "}
        <span>
          {" "}
          Precio:
          <b>
            <PrecioCremaLipiezaFacial />
          </b>{" "}
        </span>
        <br />
        <br />
      </p>
    ),
  },
  {
    id: 9,
    imagen: (
      <img
        title="Cremigel Facial  "
        className="imgProduct"
        src={cremigelFacial}
        alt="Cremigel Facial "
      ></img>
    ),
    titulo: "Cremigel Facial",
    descripcion: (
      <p className="descriptionText">
        <p className="productTitle">Cremigel Facial.</p>
        <br />
        Crema de textura liviana para pieles oleosas y mixtas. No deja sensación
        grasa, es de fácil dispersión y rápida absorción.
        <br /> Efecto hidratante y tonificante gracias a la estimulación de la
        producción de colágeno y ceramidas que logran reafirmar y mantener
        unidas las células de la epidermis haciéndola más resistente a las
        arrugas. Unifica el tono de la piel y le devuelve el brillo natural.
        Antiinflamatoria. <br />
        El activo principal de esta emulsión es la{" "}
        <em>
          <i>Niacinamida (vitamina B3)</i>
        </em>
        . Estimula la producción de colágeno, disminuye las marcas del acné en
        el rostro. Reduce la producción de sebo y el tamaño de poro evitando que
        estos se obstruyan con suciedad, bacterias y células muertas.
        <br /> Otros componentes son el{" "}
        <em>
          <i>Hidrolato de Lavanda:</i>
        </em>{" "}
        seboregulador, refrescante y calmante de pieles irritadas y enrojecidas
        y la{" "}
        <em>
          <i> Goma de Tara:</i>
        </em>{" "}
        polisacárido obtenido del endospermo de semillas de{" "}
        <em>
          <i>Caesalpina spinosa,</i>
        </em>
        nativa de Perú. Es un gelificante hidratante y filmógeno protector.
        <br /> La combinación de ácidos grasos ricos en omega 6 y omega 3 de los
        aceites de pepita de uva y de chía promueven la hidratación,
        reepitelización y regeneración celular. Mientras que el aceite esencial
        de Árbol de Te
        <em>
          <i>(Melaleuca alternifolia)</i>
        </em>{" "}
        es antifúngico y antiséptico con propiedades cicatrizantes, el aceite
        esencial de lavanda es antiinflamatorio y regenerador cutáneo de aroma
        energizante y fresco.
        <br />
        <br />
        <span>Contenido: 30 g.</span>{" "}
        <span>
          {" "}
          Precio:
          <b>
            <PrecioCremigel />
          </b>{" "}
        </span>
      </p>
    ),
  },
  {
    id: 10,
    imagen: (
      <img
        title="Crema Nutritiva Facial x 3 "
        className="imgProduct"
        src={CremaNutritiva}
        alt="Crema Nutritiva Facial x 3 "
      ></img>
    ),
    titulo: "Crema Nutritiva Facial x 3",
    descripcion: (
      <p className="descriptionText">
        <p className="productTitle">Crema Nutritiva Facial x 3.</p>
        Bomba nutritiva para pieles normales a secas con 3 activos principales.
        Humectante e hidratante, Antiage y Antioxidante. <br />
        Con el uso diario notarás la piel más tersa, firme y con brillo natural.
        Los aceites vehiculares de sésamo ( omega 6 y 9 ), Chía (omega 3)
        reepitelizan e hidratan las capas más profundas de la piel actuando como
        regeneradores de la misma. Es una crema de rápida absorción que previene
        la falta de luminosidad y el envejecimiento prematuro de la piel.
        <br /> Sus activos estrella son:
        <br />
        <em>
          <i>Niacinamida:</i>
        </em>{" "}
        activo para todo tipo de pieles. Seborregulador. Reduce el daño de la
        radiación solar en la piel atenuando la hiperpigmentación. Repara el adn
        promoviendo la elasticidad y la hidratación de la piel haciendo que esta
        sea vea más tersa y brillante. <br />
        <em>
          <i>Acido Hialurónico de Bajo y Alto peso molecular: </i>
        </em>{" "}
        son activos que alcanzan tanto la superficie como las capas más
        profundas de la piel promoviendo la producción de colágeno y elastina de
        la misma.
        <br /> La piel recupera vitalidad y volumen suavizando las arrugas.
        <br />
        <em>
          <i>Vitamina E:</i>
        </em>{" "}
        protege la piel del daño externo como los efectos provocados por la
        exposición a los rayos UV y la polución. Antioxidante.
        <br />
        <br />
        <span>Contenido: 50 g.</span>{" "}
        <span>
          {" "}
          Precio:
          <b>
            <PrecioNutriX3 />
          </b>{" "}
        </span>
      </p>
    ),
  },

  {
    id: 11,
    imagen: (
      <img
        title="crema-contorno-de-ojos"
        className="imgProduct"
        src={cremaContornoDeOjos}
        alt="Crema contorno de ojos"
      ></img>
    ),
    titulo: "Crema contorno de ojos",
    descripcion: (
      <p className="descriptionText">
        <p className="productTitle">Crema contorno de ojos.</p>
        <br />
        Este producto apunta a mantener la piel del contorno de ojos tersa y
        luminosa humectando y disminuyendo la pigmentación de las ojeras,
        desinflamando las bolsas y atenuando líneas de expresión.
        <br />
        <br />
        Las vitaminas y el ácido transretinóico de los aceites de rosas aceleran
        la regeneración de los tejidos y otorgan una acción antioxidante.
        <br />
        <br />
        <i>El aceite de rosa mosqueta </i> ( omega 3, 6 y 9 ) promueve la
        producción natural de colágeno, elastina y ácido hialurónico en las
        capas interiores de la piel. Hidrata, suaviza y aumenta la flexibilidad
        y firmeza de la misma. <br />
        <br />
        <em>
          <i>La manteca de Karité</i>
        </em>
        es un regenerador natural que protege la piel de agresiones externas y
        evita la deshidratación.
        <br />
        <br /> La
        <em>
          <i> Vitamina B5</i>
        </em>{" "}
        le confiere a la crema una acción cicatrizante y humectante. Es calmante
        de la piel irritada y enrojecida.
        <br />
        {/* <br />
        <p className="productIngredients">
          Ingredientes: Manteca de Karité, Aceite de Almendras, Cera de Abejas,
          Vitamina E y Ac Esencial de Naranjas.
        </p> */}
        <br />
        <span>Contenido: 30 g.</span>{" "}
        <span>
          {" "}
          Precio:
          <b>
            <PrecioContorno />{" "}
          </b>{" "}
        </span>
      </p>
    ),
  },
  {
    id: 12,
    imagen: (
      <img
        title="Crema-de-Manos"
        className="imgProduct"
        src={cremaDeManos}
        alt="Crema de Manos "
      ></img>
    ),
    titulo: "Crema de manos ",
    descripcion: (
      <p className="descriptionText">
        <p className="productTitle">Crema de manos.</p>
        <br />
        <b> Pomelo Rosado, Lavanda y Melisa </b>
        El lavado de manos excesivo y el uso de alcohol pueden dañar la piel.
        Esta crema reparadora genera un efecto suavizante protegiendo la capa
        lipídica de la piel de las manos de agentes externos. Su textura no
        pegajosa permite retomar la actividad rápidamente luego de su absorción.
        Ideal para manos con piel seca y agrietada.
        <br />
        <br />
        Sus activos estrella son:
        <br />
        <i>Alantoína:</i> compuesto nitrogenado proveniente de la raíz de la
        Consuelda con propiedades cicatrizantes. Ayuda a la piel a reemplazar
        las células viejas por las nuevas. Es anti-irritante y protectivo de la
        piel.
        <br />
        <i>Oleato de Caléndula:</i> extracto con alta concentración de
        flavonoides, vitamina A y E que le otorgan propiedades antioxidantes,
        regenerantes y cicatrizantes.
        <br />
        
        <i>Ac Es Pomelo Rosado:</i> su aroma tiene poder estimulante del sistema
        nervioso, promoviendo con su perfume cítrico y fresco, sensaciones de
        satisfacción y alegría. Activador de la circulación sanguínea con
        propiedades antisépticas.
        <br />
        
        <i>Pepita de uva: </i>extracto.
        <br />
        
        <em>Modo de uso:</em> Aplicar sobre manos limpias. Masajear suavemente
        hasta lograr la absorción del producto.
        <em>Advertencias:</em> Conservar en un lugar fresco, seco y protegido de
        la luz solar directa.
        <b>Contiene limoneno, linalool, citral.</b> En caso de irritación
        detenga el uso y consulte al médico.
        <br />
        <br />
        <span>Contenido: 30 / 100 g.</span>{" "}
        <span>
          {" "}
          Precio:
          <b>
            <PrecioCremaDeManos />{" "}
          </b>{" "}
        </span>
        <br />
        <br />
      </p>
    ),
  },
];

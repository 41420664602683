// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyALVBWnAElAi1hXt5NovcIL6a--5ebN-Y4",
  authDomain: "hirondina-1.firebaseapp.com",
  projectId: "hirondina-1",
  storageBucket: "hirondina-1.appspot.com",
  messagingSenderId: "944902424612",
  appId: "1:944902424612:web:44af12b7afa2c5c51df0b1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db=getFirestore(app);
import React from "react";
import logo from "../components/Imagenes/banner.jpg";
import logo_mobile from "../components/Imagenes/banner_mobile.jpg"
import "./Header.css";

// if (window.innerWidth < 1000){
//   document.getElementsByClassName('contenedorImgHeaderMobile').style.display = 'show';
// } ;
// if (window.innerWidth < 1000){
//   document.getElementsByClassName('logo_header_mobile').style.display = 'show';
// } ;
// const mq = window.matchMedia( "(max-width: 599px)" );

// if (mq.matches) {
//   document.getElementsByClassName('contenedorImgHeaderMobile').style.display = 'block';
//   document.getElementsByClassName('logo_header_mobile').style.display = 'block'};

export default function Header() {
  return (
    <header>
      <div className="contenedorImgHeader">
        <img className="logo_header" src={logo} alt="hirondina logo"></img>
      </div>
      <div className="contenedorImgHeaderMobile">
        <img className="logo_header_mobile" src={logo_mobile} alt="hirondina logo para mobiles"></img>
      </div>
      
      <br></br>
    </header>
  );
}

import React, { useEffect, useState } from 'react';
import  "./BotonCompra.css";
import {Form, Button } from "react-bootstrap";
import {AiOutlinePlusCircle, AiOutlineMinusCircle} from 'react-icons/ai';

const BotonCompra = ({producto, estado}) => {
    const [cantidad, setCantidad] = useState(0)

    useEffect(()=> {
        let prod = estado.products.filter((e)=>e.id===producto.id)[0]
        let productos = estado.products.filter((e)=>e.id!==producto.id)
        
        /* Si la cantidad 0 o menor no entra a esta condicion y el producto se borra del array*/
        if (cantidad > 0) {
            /** Si type del objeto es 'Object' quiere decir que el producto existe, sino es uno nuevo en el array */
            if (typeof(prod) === 'object') {
                prod.cantidad = cantidad            
            } else {
                prod = {
                    id: producto.id,
                    nombre: producto.titulo,
                    cantidad: cantidad,                    
                }
            }
            
            productos.push(prod)
        }

        estado.setProducts(productos)

    }, [cantidad])

    const addCantidad = (ev) => {
        let valor = parseInt(ev.target.value)
        
        setCantidad(valor >= 0 ? valor : 0)
    }

    return (
        <>
            <div className="d-flex gap-2 justify-content-center align-items-center">
                <AiOutlineMinusCircle onClick={()=>{setCantidad(cantidad === 0 ? 0 : cantidad - 1)}} className="btn-color btn-sz" />
                <Form>
                    <Form.Control className="cantidad-sz" type="number" min={0} onChange={addCantidad} value={cantidad}/>
                </Form>
                <AiOutlinePlusCircle onClick={()=>{setCantidad(cantidad + 1)}} className="btn-color btn-sz" />
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <span class="textoAcompañaBotonCompra">Una vez elegidos cada uno de los productos y la cantidad deseada, clickeá en el boton de WhatsApp.</span>
            </div>
        </>
    );
};

export default BotonCompra;

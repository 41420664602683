import { useState } from "react";
import React from "react";

export default function BotonWhatsApp(props) {
    
    let url = `https://wa.me/+5492944569374?text=Me%20gustaría%20hacerte%20una%20consulta: quiero comprar ${props.productos.map(valor => '*' + valor.nombre + '* ' + valor.cantidad + ' unidades/')}`
    
    return (
        <>
            <a href={url} className="whatsapp" target="_blank ">
                {" "}
                <i className="fa fa-whatsapp whatsapp-icon "></i>
            </a>
        </>
    );
}

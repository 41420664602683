import { React } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./NavBar.css"
import { GiSwallow} from "react-icons/gi";
import { FaRegComments, FaList} from 'react-icons/fa';
import {
  
  Link,
} from "react-router-dom";


function HirondinaNavBar() {
  return (
    <Container>
      
      
      <Navbar bg="lightblue" variant="dark" expand="lg" className="n">
        <Container>
          <Navbar.Brand>
  
           
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to={"/"} className="linkDeNav"><GiSwallow className="iconos"/ ><b>Inicio</b>   |</Link>
              
              <Link to={"https://wa.me/+5492944569374?text=Me%20gustaría%20hacerte%20una%20consulta: "} target="_blank" className="linkDeNav">    |   <FaRegComments className="iconos"/> <b>Contacto</b>   |  </Link>
                        
              
              {/* <Link to={"/quienessomos"} className="linkDeNav"><FaList className="iconos"/> <b>Quienes somos</b>     | </Link> */}
                    
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

export default HirondinaNavBar;

import React from "react";
import BotonCompra from "./BotonCompra";

const ProductosGrupo = ({ titulo, lista, esCompra, estado }) => {
    const listarProductos = 
        lista.map((prod) => (
                                <tr key={prod.id} className="trDeprocuto">
                                    <div className="publicProduct">
                                        <td className="imgProduct">{prod.imagen}</td> <td className="producDdescription">{prod.descripcion}</td>
                                        {esCompra ? <BotonCompra producto={prod} estado = {estado} /> : ''}
                                    </div>
                                </tr>
        ));

    return (
        <>
            <h3 className="titulos">{titulo}</h3>
            {listarProductos}
        </>
    );
};

export default ProductosGrupo;

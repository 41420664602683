import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import { getDocs, collection } from 'firebase/firestore';

export default function PrecioBalsamoHumec( ){


    const [preciosDeProductos, setPreciosDeProductos]=useState([]);

    const preciosRef = collection (db, "Balsamo-Humectante");

    useEffect(()=>{
        const getPreciosLista = async ()=> {
            try {
                const data= await getDocs(preciosRef);
                const filterData= data.docs.map((doc)=>({
                    ...doc.data(),
                    id: doc.id,
                }));
                setPreciosDeProductos(filterData)
            } catch (err) {
                console.error(err);
            }
        };
        getPreciosLista();
        }, []);




return(
    <span>
        {preciosDeProductos.map((precio)=>(
            
                
                <span>{precio.precio}</span>
            

        )
        
        )} 

    </span>


)}
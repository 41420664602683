import React from "react";
import "./Footer.css";
import {
  FaInstagram,
  FaFacebook,
  FaMapMarkerAlt,
  FaMicroscope,
} from "react-icons/fa";

export default function Footer() {
  return (
    <footer className= "footerHirondina">
      <h2 id="footerTitle">Redes Sociales</h2>
      <ul>
        <li className="fa-2x">
          <FaInstagram />{" "}
          <a
            target="_blank"
            rel="noreferrer"
            className="alink"
            href="https://www.instagram.com/hirondina_cosmeticanatural/"
          >
            {" "}
            Instagram
          </a>{" "}
        </li>
        <li className="fa-lg">
          <FaFacebook /> Facebook
        </li>
      </ul>
      <ul>
        <li> <FaMapMarkerAlt /> San Carlos de Bariloche, Rio Negro, Argentina.</li>
        <br/><br/>
        <li>® Hirondina Cosmetica Natural 2023.  <FaMicroscope className="fa-4x"></FaMicroscope></li>
        
        <br/><br/>
        <li>  Desarrollo Web -{" "}
        <a className="linkPortfolio"
          href="https://federico-bassi-portfolio-angular.netlify.app/"
          target="_blank"
          rel="noreferrer"
        >
          <b>Federico J Bassi</b>
        </a></li>
      </ul>


     
    </footer>
  );
}

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import HirondinaNavBar from "../src/components/NavBar";
import ProductTable from "../src/components/Tabla";
import Header from "./components/Header";
import Footer from "./components/Footer";
// import Slider from "./components/slider";
import BotonWhatsApp from "./components/BotonWhatsapp";
// import ListadoDeIntegrantes from "./components/PruebaApi";
import QuienesSomos from "./components/QuienesSomos";
import {  
   Route,
  Routes,
} from "react-router-dom";
import { useState } from "react";




function App() {
  const [products, setProducts] = useState([])

  return (
    <div className="App">
      
      <Header />
      <HirondinaNavBar />
      <header className="App-header">
        <h1>Hirondina Cosmetica Natural</h1>
        
            <Routes>
                <Route path="/" element={<ProductTable estado={{products, setProducts}} />} />
                <Route path="/quienessomos" element={<QuienesSomos/>}/>
            </Routes>
        
      
         
       
 </header>
      <br></br>
      <br></br>
      {/* <a
        href="https://wa.me/+5492944569374?text=Me%20gustaría%20hacerte%20una%20consulta:"
        className="whatsapp"
        target="_blank "
      >
        {" "}
        <i className="fa fa-whatsapp whatsapp-icon "></i>
      </a> */}
      <BotonWhatsApp productos={products}/>
      {/* <Slider></Slider> */}
      
      <Footer></Footer>
      
      {/* <ListadoDeIntegrantes></ListadoDeIntegrantes> */}
      
    </div>
  );
}

export default App;

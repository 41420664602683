import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./Tabla.css";
import { Shampoos } from "./Product";
import { Balsamos } from "./Product";
import { Cremas } from "./Product";
// import ChatApp from "./chatBot/chatBot";
import ProductosGrupo from "./ProductosGrupo";


function ProductTable({estado}) {
  return (
    <Container className="table">
      <Table id="tabla">
        <tbody className="tBodyClass">
          <ProductosGrupo titulo="cuidado facial | cremas" lista={Cremas} esCompra={true} estado={estado} />
          <ProductosGrupo titulo="cuidado facial | bálsamos" lista={Balsamos} esCompra={true} estado={estado} />
          <ProductosGrupo titulo="cuidado capilar | shampoo y acondicionadores" lista={Shampoos} esCompra={true} estado={estado} />          
        </tbody>
      </Table>
      
      {/* <ChatApp></ChatApp> */}
    </Container>
  );
}

export default ProductTable;

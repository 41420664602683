import React from "react";
import "./QuienesSomos.css"

export default function QuienesSomos(){

 return(

    <>
    <div>
    <h1 className="quienessomos">En construcción</h1>

            



    </div>
         
    </>
 )   
}